<template>
  <div>
    <Header></Header>
    <div class="kjlg1" ref="myElement">
      <div class="sy2">
        <!-- <img class="sy2img1" src="https://www.d-shang.com/image/xgw/sy/sy4.jpg" alt=""> -->
        <div class="bh">
          <img
            :class="lm1 === true ? ' ' : 'mlbh'"
            src="https://www.d-shang.com/image/xgw/sy/sy10.jpg"
            alt=""
          />
          <img
            :class="lm2 === true ? ' ' : 'mlbh'"
            src="https://www.d-shang.com/image/xgw/sy/sy11.jpg"
            alt=""
          />
          <img
            :class="lm3 === true ? ' ' : 'mlbh'"
            src="https://www.d-shang.com/image/xgw/sy/sy9.jpg"
            alt=""
          />
          <img
            :class="lm4 === true ? ' ' : 'mlbh'"
            src="https://www.d-shang.com/image/xgw/sy/sy12.jpg"
            alt=""
          />
        </div>
        <ul id="ulmd">
          <li @click="handleMouseEnter1()" style="margin-left: 0">
            <img
              :class="lm1 === true ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/2.png"
              alt=""
            />
            <img
              :class="lm1 === false ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/2s.png"
              alt=""
            />
          </li>
          <li @click="handleMouseEnter2()">
            <img
              :class="lm2 === true ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/3.png"
              alt=""
            />
            <img
              :class="lm2 === false ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/3s.png"
              alt=""
            />
          </li>
          <li @click="handleMouseEnter3()">
            <img
              :class="lm3 === true ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/4.png"
              alt=""
            />
            <img
              :class="lm3 === false ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/4s.png"
              alt=""
            />
          </li>
          <li @click="handleMouseEnter4()">
            <img
              :class="lm4 === true ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/5.png"
              alt=""
            />
            <img
              :class="lm4 === false ? 'sy2ulbhs' : 'sy2ulbh'"
              src="../../images/kjlg/5s.png"
              alt=""
            />
          </li>
        </ul>


        <!-- 吊顶大模块1 -->

        <div :class="lm1 === true ? 'listtop1' : 'listtopnone'">
          <ul class="listul">
            <li
              @click="listtop1()"
              :class="listtop1s === true ? 'sy2listulli' : ''"
            >
              客餐厅
            </li>
            <li
              @click="listtop2()"
              :class="listtop2s === true ? 'sy2listulli' : ''"
            >
              厨房
            </li>
            <li
              @click="listtop3()"
              :class="listtop3s === true ? 'sy2listulli' : ''"
            >
              卧室
            </li>
            
            <li
              @click="listtop4()"
              :class="listtop4s === true ? 'sy2listulli' : ''"
            >
              卫浴
            </li>
            <li
              @click="listtop5()"
              :class="listtop5s === true ? 'sy2listulli' : ''"
            >
              其他
            </li>
          </ul>
           
           <!-- 效果图大模块1 -->
          <div :class="listtop1s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href=""></a> -->
                <!-- <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>

            <el-pagination @current-change="listtop1" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
          

          <div :class="listtop2s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop2" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop3s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop3" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop4s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop4" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop5s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop5" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>




        <!-- 墙面大模块2 -->

        <div :class="lm2 === true ? 'listtop1' : 'listtopnone'">
          <ul class="listul">
            <li
              @click="listtop12()"
              :class="listtop1s === true ? 'sy2listulli' : ''"
            >
              客厅
            </li>
            <li
              @click="listtop22()"
              :class="listtop2s === true ? 'sy2listulli' : ''"
            >
              餐厅
            </li>
            <li
              @click="listtop32()"
              :class="listtop3s === true ? 'sy2listulli' : ''"
            >
              卧室
            </li>
            
            <li
              @click="listtop42()"
              :class="listtop4s === true ? 'sy2listulli' : ''"
            >
              书房
            </li>
            <li
              @click="listtop52()"
              :class="listtop5s === true ? 'sy2listulli' : ''"
            >
              其他
            </li>
          </ul>
           
           <!-- 效果图大模块 -->
          <div :class="listtop1s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href=""></a> -->
                <!-- <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>

            <el-pagination @current-change="listtop12" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
          

          <div :class="listtop2s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop22" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop3s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop32" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop4s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop42" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop5s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop52" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>




        <!-- 定制门柜大模块3 -->

        <div :class="lm3 === true ? 'listtop1' : 'listtopnone'">
          <ul class="listul">
            <li
              @click="listtop13()"
              :class="listtop1s === true ? 'sy2listulli' : ''"
            >
              衣柜
            </li>
            <li
              @click="listtop23()"
              :class="listtop2s === true ? 'sy2listulli' : ''"
            >
              餐边柜
            </li>
            <li
              @click="listtop33()"
              :class="listtop3s === true ? 'sy2listulli' : ''"
            >
              电视柜
            </li>
            
            <li
              @click="listtop43()"
              :class="listtop4s === true ? 'sy2listulli' : ''"
            >
              阳台柜
            </li>
            <li
              @click="listtop53()"
              :class="listtop5s === true ? 'sy2listulli' : ''"
            >
              其他 
            </li>
          </ul>
           
           <!-- 效果图大模块1 -->
          <div :class="listtop1s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href=""></a> -->
                <!-- <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>

            <el-pagination @current-change="listtop13" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
          

          <div :class="listtop2s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop23" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop3s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop33" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop4s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop43" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop5s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop53" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>




        <!-- 阳台生活大模块4 -->

        <div :class="lm4 === true ? 'listtop1' : 'listtopnone'">
          <ul class="listul">
            <li
              @click="listtop14()"
              :class="listtop1s === true ? 'sy2listulli' : ''"
            >
              休闲阳台
            </li>
            <li
              @click="listtop24()"
              :class="listtop2s === true ? 'sy2listulli' : ''"
            >
              健身阳台
            </li>
            <li
              @click="listtop34()"
              :class="listtop3s === true ? 'sy2listulli' : ''"
            >
              亲子阳台
            </li>
            <li
              @click="listtop44()"
              :class="listtop4s === true ? 'sy2listulli' : ''"
            >
              茶歇阳台
            </li>
            <li
              @click="listtop54()"
              :class="listtop5s === true ? 'sy2listulli' : ''"
            >
              园艺阳台
            </li>
          </ul>
           
           <!-- 效果图大模块1 -->
          <div :class="listtop1s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href=""></a> -->
                <!-- <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>

            <el-pagination @current-change="listtop14" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
          

          <div :class="listtop2s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop24" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop3s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop34" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop4s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop44" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>

          <div :class="listtop5s === true ? ' ' : 'listdatanone'">
            <div class="listdata">
              <div v-for="(value,index) in list" :key="index" class="listdatadiv">
                <img :src="'https://www.d-shang.com'+value.thumb" alt="" />
                <!-- <span>{{value.title}}</span>
                <a href="">{{ value.subtitle}}</a> -->
              </div>
            </div>
            <el-pagination @current-change="listtop54" :page-count="pagecount"
          :current-page.sync="p" background layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>



      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/HoMe.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Space",
  data() {
    return {
      lm1: true,
      lm2: false,
      lm3: false,
      lm4: false,
      listtop1s: true,
      listtop2s: false,
      listtop3s: false,
      listtop4s: false,
      listtop5s: false,
      list:[],
      pagecount:1,
      p:1
    };
  },

  components: {
    Header,
    Footer,
  },

  methods: {
    route(path) {
      this.$router.push({
        path: path,
      });
    },

    handleMouseEnter1() {
      console.log("测试进入1");
      this.lm2 = false;
      this.lm3 = false;
      this.lm4 = false;
      this.lm1 = true;
      this.p=1;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
      if(this.listtop1s){
        this.listtop1();
      }else if(this.listtop2s){
        this.listtop2();
      }else if(this.listtop3s){
        this.listtop3();
      }else if(this.listtop4s){
        this.listtop4();
      }else if(this.listtop5s){
        this.listtop5();
      }

    },
    handleMouseEnter2() {
      console.log("测试进入2");
      this.lm1 = false;
      this.lm3 = false;
      this.lm4 = false;
      this.lm2 = true;
      this.p=1;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
      if(this.listtop1s){
        this.listtop12();
      }else if(this.listtop2s){
        this.listtop22();
      }else if(this.listtop3s){
        this.listtop32();
      }else if(this.listtop4s){
        this.listtop42();
      }else if(this.listtop5s){
        this.listtop52();
      }

    },
    handleMouseEnter3() {
      console.log("测试进入3");
      this.lm2 = false;
      this.lm1 = false;
      this.lm4 = false;
      this.lm3 = true;
      this.p=1;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
      if(this.listtop1s){
        this.listtop13();
      }else if(this.listtop2s){
        this.listtop23();
      }else if(this.listtop3s){
        this.listtop33();
      }else if(this.listtop4s){
        this.listtop43();
      }else if(this.listtop5s){
        this.listtop53();
      }

    },
    handleMouseEnter4() {
      console.log("测试进入4");
      this.lm2 = false;
      this.lm3 = false;
      this.lm1 = false;
      this.lm4 = true;
      this.p=1;
      console.log(this.lm1, this.lm2, this.lm3, this.lm4);
      if(this.listtop1s){
        this.listtop14();
      }else if(this.listtop2s){
        this.listtop24();
      }else if(this.listtop3s){
        this.listtop34();
      }else if(this.listtop4s){
        this.listtop44();
      }else if(this.listtop5s){
        this.listtop54();
      }

    },



    //吊顶

    sjerror(value){
      var that=this;
      const cid=value;
     this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid="+cid+"&p=1"+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
     .then(function(res){
       that.list=res.data.data
        that.pagecount = res.data.message.page;
     })
    },

    listtop1() {
      var that=this;
      this.listtop1s = true;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2236&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2236);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop2() {
      var that=this;
      this.listtop1s = false;
      this.listtop2s = true;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2238&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
      if(res.data=='超过了最大分页数'){
        that.sjerror(2238);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
        
      })
    },
    listtop3() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = true;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2237&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2237);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop4() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = true;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2239&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2239);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop5() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = true;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2240&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2240);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },




    // 墙面
    listtop12() {
      var that=this;
      this.listtop1s = true;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2241&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2241);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop22() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = true;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2243&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2243);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop32() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = true;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2242&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2242);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop42() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = true;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2244&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2244);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop52() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = true;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2245&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2245);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },



    //门柜
    listtop13() {
      var that=this;
      this.listtop1s = true;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2246&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2246);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop23() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = true;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2247&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
        if(res.data=='超过了最大分页数'){
        that.sjerror(2247);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop33() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = true;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2248&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2248);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop43() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = true;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2250&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2250);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop53() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = true;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2249&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2249);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },



    // 阳台
    listtop14() {
      var that=this;
      this.listtop1s = true;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2251&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2251);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop24() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = true;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2252&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2252);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop34() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = true;
      this.listtop4s = false;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2253&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2253);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop44() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = true;
      this.listtop5s = false;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2254&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2254);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },
    listtop54() {
      var that=this;
      // that.p=1
      this.listtop1s = false;
      this.listtop2s = false;
      this.listtop3s = false;
      this.listtop4s = false;
      this.listtop5s = true;

      this.$axios.get("https://www.d-shang.com/index.php?app/getnewslistkj/?cid=2255&p="+this.p+"&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw")
      .then(function(res){
         if(res.data=='超过了最大分页数'){
        that.sjerror(2255);
      }else{
      that.list=res.data.data
        that.pagecount = res.data.message.page;
      }
      })
    },

    kjlg1sbh(){
      const width=window.innerWidth;
      const height=window.innerHeight;
      // width.style.width='80%';
      // width.style.background='red';
      // height.style.width='80%';
      // height.style.background='red';
      console.log(width,height,999);

      // setTimeout(()=>{
      //  width.style.width='80%';
      // width.style.background='red';
      // height.style.width='80%';
      // height.style.background='red';
      // console.log(width,height,999);  
      // },2000)
      // setTimeout(() => {
      //   width.style.width='100%';
      // width.style.background='pink';
      // }, 3000);
      
    }
  },

  mounted() {
    this.listtop1();
    // this.kjlg1sbh();
  },
};
</script>

<style lang="stylus" scoped>
 
  .kjlg1s{
    width:100%;
    height:100%;
  }

 .listtopnone{
   display: none;
 }

.custom-class {
  --el-tag-bg-color: red;
}

.sy2 .listdatanone {
  display: none;
}

.listdatadiv span {
  font-size: 19px;
  color: #000;
  display: inline-block;
  margin-top: 14px;
  margin-left: 38px;
  float: left;
}

.listdatadiv a {
  display: inline-block;
  font-size: 14px;
  color: #a9a6a6;
  float: right;
  margin-top: 22px;
  margin-right: 22px;
}

.listdatadiv img {
  display: block;
 image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    // height: 242px;
    // width:363px;
    width:100%;
    height:100%;
}

.sy2 .listdata {
  width: 108%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom:30px;
}

.listdata .listdatadiv {
  // flex: 1;
  height: 240px;
  width:360px;
  margin: 0 72px 45px 0;  //原28px
  // background-color: #f2f2f2;
  // width: calc(((100% - 290px) / 3)); // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整 原84px
  // min-width: calc(((100% - 240px) / 3)); // 加入min-width、max-width后每个child的宽度就生效了
  // max-width: calc(((100% - 240px) / 3));
}

.listtop1 {
  margin-top: 30px;
  width: 64.5%;
  margin-left: 340px;
}


.sy2 .listul li {
  color: #000;
  width: 287px;
  margin: 0;
  height: 40px;
  cursor: pointer;
  font-size:18px;
}

.sy2listulli {
  color: #000;
  width: 287px;
  margin: 0;
  height: 40px;
  border-bottom: 4px solid #ccad65;
}

.sy2 .listul {
  height: 40px;
  border-bottom: 4px solid #e3e3e3;
  margin-bottom: 45px;
}

.sy2ulbh {
  display: inline-block;
}

.sy2ulbhs {
  display: none;
}

.mlbh {
  display: none !important;
}

.sy2 .bh img {
  hieght: 700px;
  width: 100%;
  display: block;
}

.sy2 ul li img {
  width: 78px;
  height: 81px;
}

.sy2 ul li {
  margin-top: 20px;
  width: 277px;
  padding: 0px;
  margin-left: 105px;
}

.sy2 ul {
  display: flex;
  justify-content: center;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  margin-top:15px;
}

.sy2 {
  margin-top: 87px;
  margin-bottom:110px;
}
</style>

<style>
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #cbad65 !important;
    color: #fff;
  }
</style>