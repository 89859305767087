<template>
  <div class="box">
    <!-- <Header></Header> -->

    <div class="header">
      <img class="img1s" src="https://www.d-shang.com/image/xgw/sy/LOGO.png" alt="" />
      <ul>
        <li @click="route('/index')"><a>首页</a></li>
        <li @click="route('/space')"><a href="">空间灵感</a></li>
        <li class="headergydsli">
          <a href="">关于顶上</a>
          <div class="headergyds">
            <ul>
              <li>
                <a style="border: 0" href="#gydiv1md"> 企业简介</a>
              </li>
              <li><a style="border: 0" href="#gydiv2md">企业文化</a></li>
              <li><a style="border: 0" href="#gydiv3md">光辉历程</a></li>
              <li><a style="border: 0" href="#gydiv4md">企业荣誉</a></li>
            </ul>
          </div>
        </li>
        <li @click="route('/news')" class="headergyds2li">
          <a href="">新闻中心</a>
          <div class="headergyds2">
            <ul>
              <li class="hoverli1">品牌资讯</li>
              <li class="hoverli1">活动咨询</li>
              <li class="hoverli1">产品咨询</li>
            </ul>
          </div>
        </li>
        <li @click="route('/join')"><a href="">招商加盟</a></li>
        <li @click="route('/contact')"><a href="">联系我们</a></li>
      </ul>
      <div class="login">
        <img class="img2" src="https://www.d-shang.com/image/xgw/sy/login.png" alt="" />
          <a style="cursor:pointer;" @click="dates()" target="_blank">经销商登录</a>
      </div>
    </div>

    <img class="img1" src="../../images/gyds/1s.jpg" alt="" />
    <div class="gydiv1" id="gydiv1md">
      <img class="img2" src="../../images/gyds/2.jpg" alt="" />
      <span class="div1span">
        <div class="tran-middle"></div>
      </span>
    </div>

    <div class="gydiv2" id="gydiv2md">
      <div class="div21">
        <img
          :class="lm1 === true ? 'imgbhs' : 'imgbh'"
          @mouseenter="handleMouseEnter1"
          src="https://www.d-shang.com/image/xgw/gyds/sm1.jpg"
          alt=""
        />
        <img
          :class="lm1 === true ? 'imgbh' : 'imgbhs'"
          src="https://www.d-shang.com/image/xgw/gyds/sm2.jpg"
          alt=""
        />
        <img
          :class="lm2 === true ? 'imgbhs' : 'imgbh'"
          @mouseenter="handleMouseEnter2"
          src="https://www.d-shang.com/image/xgw/gyds/yj1.jpg"
          alt=""
        />
        <img
          :class="lm2 === true ? 'imgbh' : 'imgbhs'"
          src="https://www.d-shang.com/image/xgw/gyds/yj2.jpg"
          alt=""
        />
        <img
          :class="lm3 === true ? ' ' : 'imgbh'"
          @mouseenter="handleMouseEnter3"
          class="div21img1"
          src="https://www.d-shang.com/image/xgw/gyds/jzg1.jpg"
          alt=""
        />
        <img
          :class="lm3 === true ? 'imgbh' : 'imgbhs'"
          class="div21img1"
          src="https://www.d-shang.com/image/xgw/gyds/jzg2.jpg"
          alt=""
        />
      </div>
      <div class="div22">
        <img
          :class="lm1 === true ? 'imgbh' : 'imgbhs'"
          src="https://www.d-shang.com/image/xgw/gyds/sm3.jpg"
          alt=""
        />
        <img
          :class="lm2 === true ? 'imgbh' : 'imgbhs'"
          src="https://www.d-shang.com/image/xgw/gyds/yj3.jpg"
          alt=""
        />
        <img
          :class="lm3 === true ? 'imgbh' : 'imgbhs'"
          src="https://www.d-shang.com/image/xgw/gyds/jzg3.jpg"
          alt=""
        />
      </div>
    </div>

    <div class="gydiv3" id="gydiv3md">
      <div class="gydiv3div">
        <div :style="paragraphStyle" id="developYears">
          <div id="line"></div>
          <div id="dotList">
            <div
              v-for="(item, index) in yearsList"
              :key="index"
              :class="item.id == yearsActive ? 'dot' : 'dotNo'"
            >
              <!-- //此图片是选中之后头部的指示图片 ☞ -->
              <!-- <img v-if="item.id == yearsActive" class="activeImg" src="../../images/gyds/345.png" alt="">
			<div v-else class="nullBox"></div> -->
              <dl @click="yearsClick(item)">
                <dt>
                  <!-- //此图片是选中之后的圆点图片 -->
                  <img
                    v-show="item.id == yearsActive"
                    src="../../images/gyds/345.png"
                    alt=""
                  />
                  <!-- //此图片是未选中的圆点图片 -->
                  <img
                    v-show="item.id != yearsActive"
                    src="../../images/gyds/212.png"
                    alt=""
                  />
                </dt>
                <dd>{{ item.year }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <img
        v-if="yearsActive == 1"
        class="imgs"
        src="../../images/gyds/41.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 2"
        class="imgs"
        src="../../images/gyds/42.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 3"
        class="imgs"
        src="../../images/gyds/43.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 4"
        class="imgs"
        src="../../images/gyds/44.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 5"
        class="imgs"
        src="../../images/gyds/45.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 6"
        class="imgs"
        src="../../images/gyds/46.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 7"
        class="imgs"
        src="../../images/gyds/47.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 8"
        class="imgs"
        src="../../images/gyds/48.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 9"
        class="imgs"
        src="../../images/gyds/49.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 10"
        class="imgs"
        src="../../images/gyds/50.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 11"
        class="imgs"
        src="../../images/gyds/51.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 12"
        class="imgs"
        src="../../images/gyds/52.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 13"
        class="imgs"
        src="../../images/gyds/53.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 14"
        class="imgs"
        src="../../images/gyds/54.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 15"
        class="imgs"
        src="../../images/gyds/55.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 16"
        class="imgs"
        src="../../images/gyds/56.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 17"
        class="imgs"
        src="../../images/gyds/57.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 18"
        class="imgs"
        src="../../images/gyds/58.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 19"
        class="imgs"
        src="../../images/gyds/59.jpg"
        alt=""
      />
      <img
        v-if="yearsActive == 20"
        class="imgs"
        src="../../images/gyds/60.jpg"
        alt=""
      />
      <!-- <button style="border:0;" @click="left(yearsActive)" v-preventMultiClick="200"> -->
     <img  
       @click="left(yearsActive)"
        v-preventMultiClick="2000"
        class="img1"
        src="../../images/gyds/jt1.png"
        alt=""
      />
      <!-- </button> -->
      
      <!-- <button style="opacity:0;" @click="right(yearsActive)" v-preventMultiClick="200"> -->
      <img
      @click="right(yearsActive)" v-preventMultiClick="200"
        class="img2"
        src="../../images/gyds/jt2.png"
        alt=""
      />
      <!-- </button> -->
      
    </div>

    <div class="gydiv4" id="gydiv4md">
      <el-carousel :interval="5000" arrow="always">
    <el-carousel-item v-for="(item,index) in item" :key="index">
      <div
            style="
              color: #000000;
              text-align: center;
              overflow: hidden;
              width: 100%;
            "
          >
            <img
              style="width: 100%; height: auto"
              class="bannertt"
              :src="item.url"
              alt="Image"
            />
          </div>
      <!-- <h3>{{ item }}</h3> -->
    </el-carousel-item>
  </el-carousel>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/HoMe.vue";
import Footer from "@/components/Footer.vue";
import img1 from "../../images/lb/1.jpg"
import img2 from "../../images/lb/2.jpg"
import img3 from "../../images/lb/3.jpg"
import img4 from "../../images/lb/4.jpg"
import img5 from "../../images/lb/5.jpg"
import img6 from "../../images/lb/6.jpg"
import img7 from "../../images/lb/7.jpg"
import img8 from "../../images/lb/8.jpg"
export default {
  name: "About",
  data() {
    return {
      lm1: false,
      lm2: true,
      lm3: true,
      paragraphStyle: {
        marginLeft: "-100px",
      },
      yearsActive: 1,
      yearsList: [
        {
          id: 1,
          year: "2000",
          value: "",
        },
        {
          id: 2,
          year: "2001",
          value: "",
        },

        {
          id: 3,
          year: "2003",
          value: "",
        },
        {
          id: 4,
          year: "2004",
          value: "",
        },
        {
          id: 5,
          year: "2005",
          value: "",
        },
        {
          id: 6,
          year: "2006",
          value: "",
        },
        {
          id: 7,
          year: "2007",
          value: "",
        },
        {
          id: 8,
          year: "2009",
          value: "",
        },
        {
          id: 9,
          year: "2012",
          value: "",
        },
        {
          id: 10,
          year: "2014",
          value: "",
        },
        {
          id: 11,
          year: "2015",
          value: "",
        },
        {
          id: 12,
          year: "2016",
          value: "",
        },
        {
          id: 13,
          year: "2017",
          value: "",
        },
        {
          id: 14,
          year: "2018",
          value: "",
        },
        {
          id: 15,
          year: "2019",
          value: "",
        },
        {
          id: 16,
          year: "2020",
          value: "",
        },
        {
          id: 17,
          year: "2021",
          value: "",
        },
        {
          id: 18,
          year: "2022",
          value: "",
        },
        {
          id: 19,
          year: "2023",
          value: "",
        },
        {
          id: 20,
          year: "2024",
          value: "",
        },
      ],
      item:[{ id: 1, type: "image", url: img1 },
        { id: 2, type: "image", url: img2 },
        { id: 3, type: "image", url: img3 },
        { id: 4, type: "image", url: img4 },
        { id: 5, type: "image", url: img5 },
        { id: 6, type: "image", url: img6 },
        { id: 7, type: "image", url: img7 },
        { id: 8, type: "image", url: img8 }]
    };
  },


  watch: {
    yearsActive: {
      handler(newValue, oldValue) {
        console.log(999, newValue, oldValue);
        if (newValue != oldValue) {
          this.newValueyears(newValue);
        }
      },
    },
  },

  methods: {

    dates(){
     this.date=Date.now();
     window.open('https://www.dingshangjc.com:6090?r='+this.date,'_blank');
    },

    newValueyears(value) {
      this.yearsActive = value;
      console.log(this.yearsActives, 77777777);
      if (this.yearsActive > 20) {
        this.yearsActive = 20;
      }
      if (this.yearsActive < 1) {
        this.yearsActive = 1;
      }
    },

    route(path) {
      this.$router.push({
        path: path,
      });
    },

    left(value) {
      console.log(value);
      let cc = value;
      this.yearsActiv = cc;
      console.log(this.yearsActiv, 121212);
      if (this.yearsActiv > 1) {
        this.yearsActive = this.yearsActive - 1;
      }
      console.log(this.yearsActive);
      let counts = this.paragraphStyle.marginLeft;
      if (this.yearsActive <= 4) {
        this.paragraphStyle.marginLeft = "-100px";
      } else {
        if (counts.length > 6) {
          counts = counts.substring(1, 5) * 1;
        } else {
          counts = counts.substring(1, 4) * 1;
        }
        var stynum = counts - 170;
        this.paragraphStyle.marginLeft = -stynum + "px";
      }
    },
    right(value) {
      console.log(this.yearsActive, "aaaaa22222");
      if (this.yearsActiv > 19) {
        this.yearsActive = this.yearsActive;
      } else {
        this.yearsActive = this.yearsActive + 1;
      }

      console.log(this.yearsActive, 11111111111111);
      let counts = this.paragraphStyle.marginLeft;
      if (this.yearsActive > 15) {
        this.paragraphStyle.marginLeft = "-2200px";
      } else {
        if (counts.length > 6) {
          counts = counts.substring(1, 5) * 1;
        } else {
          counts = counts.substring(1, 4) * 1;
        }
        var stynum = counts + 170;
        this.paragraphStyle.marginLeft = -stynum + "px";
      }
      console.log(this.yearsActive, 21212121);
    },
    yearsClick(row) {
      if (row.id <= 4) {
        this.yearsActive = row.id;
        this.paragraphStyle.marginLeft = "-100px";
      }

      if (row.id > 15) {
        this.yearsActive = row.id;
        this.paragraphStyle.marginLeft = "-2200px";
      }

      if (row.id < this.yearsActive) {
        console.log("减少");
        let count = (this.yearsActive - row.id) * 250;
        let counts;
        console.log(count, "减少px");
        console.log(this.paragraphStyle.marginLeft, "6666");
        counts = this.paragraphStyle.marginLeft;
        if (counts.length > 6) {
          counts = counts.substring(1, 5) * 1;
        } else {
          counts = counts.substring(1, 4) * 1;
        }
        console.log(counts, "截取数字");
        if (this.yearsActive == 16) {
          var stynum = counts - count + 200;
        } else if (this.yearsActive == 17) {
          var stynum = counts - count + 400;
        } else if (this.yearsActive == 18) {
          var stynum = counts - count + 600;
        } else if (this.yearsActive == 19) {
          var stynum = counts - count + 800;
        } else if (this.yearsActive == 20) {
          var stynum = counts - count + 1000;
        } else if (this.yearsActive < 16) {
          var stynum = counts - count;
        }
        this.paragraphStyle.marginLeft = -stynum + "px";
        console.log(this.paragraphStyle.marginLeft, "减过以后的值");
      }

      if (row.id > this.yearsActive) {
        console.log("增加");
        console.log(row.id, this.yearsActive, "两个值");
        let count = (row.id - this.yearsActive) * 170;
        let counts;
        counts = this.paragraphStyle.marginLeft;
        console.log(counts, "原数值");
        if (counts.length > 6) {
          counts = counts.substring(1, 5) * 1;
        } else {
          counts = counts.substring(1, 4) * 1;
        }
        console.log(counts, "原数值2");
        var stynum = counts + count;
        console.log(stynum);
        this.paragraphStyle.marginLeft = -stynum + "px";
      }
      this.yearsActive = row.id;
      // console.log(this.yearsActive);
    },

    handleMouseEnter1() {
      this.lm1 = false;
      this.lm2 = true;
      this.lm3 = true;
    },
    handleMouseEnter2() {
      this.lm2 = false;
      this.lm1 = true;
      this.lm3 = true;
    },
    handleMouseEnter3() {
      this.lm3 = false;
      this.lm2 = true;
      this.lm1 = true;
    },
  },

  components: {
    Header,
    Footer
  },

  mounted() {},
};
</script>



<style lang="stylus" scoped>
.el-carousel {
  width: 1339px;
  height: 406px;
  overflow:hidden;
  margin-top:380px;
  margin-left:100px;
  padding-left:200px;
  padding-right:200px;


  .el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
  }

  /deep/ .el-carousel__indicators { // 指示器
    left: unset;
    transform: unset;
    right: 48%;
    bottom: -50px;
    display: none;
  }

  /deep/ .el-carousel__button { // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 1;
  }

  /deep/ .is-active .el-carousel__button { // 指示器激活按钮
    background: #cbad65;
    opacity :0;
  }

  /deep/ .el-carousel__container {
    height: 100%;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
  margin: 0;
  padding: 0;
}

//  /* 左右箭头宽高 */
/deep/ .el-carousel__arrow{
    height: 40px;
    width: 40px
}

//切换左右箭头样式
/deep/ .el-carousel__arrow--left {
  color: transparent;
  // 设置背景图片
  background: url("../../images/gyds/jt1.png") no-repeat center center;
  background-size: 40px;
  position:relative;
  left:-720px;
}
/deep/ .el-carousel__arrow--right {
  color: transparent;
  // 设置背景图片
  background: url("../../images/gyds/jt2.png") no-repeat center center;
  background-size: 40px;
  position:relative;
  right:-720px;
}

@media(max-width:1830px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
  right:-720px;
  }
}

@media(max-width:1823px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 36px;
  right:-620px;
  }
}
@media(max-width:1623px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
  right:-590px;
    background-size: 36px;
  background-size: 30px;
  }
}
@media(max-width:1523px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 30px;
  right:-560px;
  }
}
@media(max-width:1400px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 30px;
  right:-510px;
  }
}
@media(max-width:1300px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 30px;
  right:-490px;
  }
}
@media(max-width:1250px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 26px;
  right:-450px;
  }
}
@media(max-width:1100px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 26px;
  right:-400px;
  }
}
@media(max-width:1000px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 22px;
  right:-370px;
  }
}
@media(max-width:900px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 20px;
  right:-340px;
  }
}
@media(max-width:800px){
  /deep/ .el-carousel__arrow--right{
    position:relative;
    background-size: 18px;
  right:-300px;
  }
}

/deep/ .el-carousel__arrow--left:hover{
    background: url("../../images/gyds/jt1.png") no-repeat center center;
}
/deep/ .el-carousel__arrow--right:hover{
    background: url("../../images/gyds/jt2.png") no-repeat center center;
}



.gydiv4 {
  height: 949px;
  width: 100%;
  background: url('../../images/gyds/7.jpg');
  background-size: cover;
  display: flex;
}

.imgs {
  height: 503px;
  width: 1283px;
  margin-left: 98px;
}

.gydiv3 {
  height: 1150px;
  width: 100%;
  background: url('../../images/gyds/4.jpg');
  overflow: hidden;
  background-size: cover;
}

.gydiv3 .img1 {
  width: 40px;
  height: 40px;
  position: relative;
  top: -604px;
  left: -1405px;
  z-index: 5;
  cursor:pointer;
}

.gydiv3 .img2 {
  width: 40px;
  height: 40px;
  position: relative;
  top: -604px;
  left: 35px;
  cursor:pointer;
}

.gydiv3div {
  overflow: hidden;
  width: 70%;
  margin-left: 295px;
  // background:red;
  margin-top: 294px;
  height: 220px;
}

#developYears {
  height: 200px;
  position: relative;
  z-index: 1;
  padding-top: 100px;

  #line {
    position: absolute;
    width: 100%;
    height: 3px;
    background: #DEDCDC;
  }

  #dotList {
    width: 100%;
    // display: flex;
    // justify-content: space-around;
    position: absolute;
    top: 38px;
    margin-top: 58px;

    .dot {
      text-align: center;
      z-index: 3;

      .activeImg {
        width: 10px;
      }

      dl {
        cursor: pointer;
        float: left;
        margin-left: 125px;
        margin-bottom: 500px;

        dt {
          img {
            width: 10px;
            z-index: 2;
          }
        }

        dd {
          font-size: 18px;
          font-family: 'FZKTK--GBK1-0', 'FZKTK--GBK1';
          font-weight: normal;
          color: #cbad65;
          line-height: 20px;
          letter-spacing: 1px;
          -webkit-text-stroke: 1px #cbad65;
          text-stroke: 1px #FC671E;
          margin-left: 0px;
          margin-top: 30px;
        }
      }
    }

    .dotNo {
      text-align: center;
      z-index: 3;

      .activeImg {
        width: 56px;
      }

      .nullBox {
        width: 35px;
        height: 43px;
      }

      dl {
        cursor: pointer;
        float: left;
        margin-left: 125px;
        margin-bottom: 500px;

        dt {
          img {
            width: 10px;
          }
        }

        dd {
          font-size: 18px;
          font-family: 'FZKTK--GBK1-0', 'FZKTK--GBK1';
          font-weight: normal;
          color: #000;
          line-height: 20px;
          letter-spacing: 1px;
          -webkit-text-stroke: 1px #000;
          text-stroke: 1px #666666;
          margin-left: 0px;
          margin-top: 30px;
        }
      }
    }
  }
}

.imgbh {
  display: none;
}

.imgbhs {
  display: block;
}

.div21img1 {
  height: 186px;
}

.div22 img {
  height: 566px;
  width: 1190px;
  // width:1090px;
}

.div22 {
  float: left;
  width: 1090px;
  overflow: hidden;
}

.div21 {
  float: left;
  width: 761px;
  overflow: hidden;
}

.gydiv2 {
  height: 566px;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #1F1F1F;
  overflow: hidden;
}

.div21 img {
  width: 811px;
  // width:761px;
  height: 190px;
}

.box {
  font-size: 0;
}

.img1 {
  width: 100%;
  height: 717px;
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-top: 87px;
}

.gydiv1 {
  width: 100%;
  height: 996px;
  position: relative;
  margin: 0;
  padding: 0;
}

.img2 {
  width: 100%;
  height: 996px;
}

.div1span {
  display: block;
  position: absolute;
  left: 1225px;
  top: 726px;
  background: #cbad65;
  width: 14px;
  height: 14px;
  border-radius: 14px;
}

@keyframes gradient {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }

  5% {
    transform: translate(-50%, -50%) scale(0.05);
    opacity: 0.05;
  }

  10% {
    transform: translate(-50%, -50%) scale(0.1);
    opacity: 0.1;
  }

  15% {
    transform: translate(-50%, -50%) scale(0.15);
    opacity: 0.15;
  }

  20% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0.2;
  }

  25% {
    transform: translate(-50%, -50%) scale(0.25);
    opacity: 0.25;
  }

  30% {
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0.3;
  }

  35% {
    transform: translate(-50%, -50%) scale(0.35);
    opacity: 0.35;
  }

  40% {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: 0.4;
  }

  45% {
    transform: translate(-50%, -50%) scale(0.45);
    opacity: 0.45;
  }

  50% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.5;
  }

  60% {
    transform: translate(-50%, -50%) scale(0.6);
    opacity: 0.45;
  }

  65% {
    transform: translate(-50%, -50%) scale(0.65);
    opacity: 0.4;
  }

  70% {
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.35;
  }

  75% {
    transform: translate(-50%, -50%) scale(0.75);
    opacity: 0.3;
  }

  80% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.25;
  }

  85% {
    transform: translate(-50%, -50%) scale(0.85);
    opacity: 0.2;
  }

  90% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0.15;
  }

  95% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0.1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.div1span div {
  width: 80px;
  height: 80px;
  background: #cbad65;
  border: 1px solid #cbad65;
  opacity: 0.3;
  -webkit-animation: warn 2s ease-out;
  -moz-animation: warn 2s ease-out;
  animation: gradient 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 50%;
}

.tran-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.headergyds2 ul li {
  line-height: 127px;
  cursor: pointer;
}

.headergyds2 ul {
  margin-left: 913px;
}

.headergyds2 {
  position: fixed;
  left: 0;
  top: 100px;
  height: 127px;
  width: 1920px;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy13.png');
  display: none;
}

.headergyds2li:hover .headergyds2 {
  display: block;
}

.headergyds ul li {
  line-height: 127px;
  cursor: pointer;
}

.headergyds ul {
  margin-left: 675px;
}

.headergyds {
  position: fixed;
  left: 0;
  top: 100px;
  height: 127px;
  width: 1920px;
  background-image: url('https://www.d-shang.com/image/xgw/sy/sy13.png');
  display: none;
}

.headergydsli:hover .headergyds {
  display: block;
}

a {
  text-decoration: none;
}

.login {
  align-items: center;
  position: relative;
  margin-right: 30px;
}

.login a {
  color: #ffffff;
  display: inline-block;
  line-height: 26px;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  left: 18px;
  width: 80px;
  font-size: 14px;
}

ul {
  display: flex;
  margin-right: -200px;
}

ul li {
  list-style-type: none;
  color: #fff;
  padding: 0 50px 0 50px;
  font-size: 15px;
  height: 100px;
  /* background: chartreuse; */
}

ul li a {
  color: #ffffff;
  display: inline-block;
  height: 97px;
  cursor: pointer;
  line-height: 100px;
  /* transition:all 250ms linear; */
}

li a:hover {
  color: #cbad65;
  border-bottom: 3px solid #cbad65;
}

.hoverli1:hover{
  color:#cbad65;
}

.block {
  margin-top: 100px;
}

.header {
  height: 100px;
  width: 100%;
  background: #000000;
  display: flex;
  /* justify-content:space-between; */
  justify-content: space-around;
  /* line-height:100px; */
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header .img1s {
  width: 236px;
  height: 35px;
  display: inline-block;
  margin-left: -80px;
}

.header .img2 {
  width: 23px;
  height: 26px;
  margin-top: 11px;
}
</style>

