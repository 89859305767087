<template>
  <div>
      <Header></Header>
      <img class="img1" src="../../images/lxwm/1.jpg" alt="">
      <Address></Address>
      <div class="img2"> 
<div class="zsdiv1">
        <input placeholder="姓名" v-model="name" type="text" />
        <input placeholder="电话" v-model="phone" type="text" />
        <input placeholder="电子邮箱" v-model="ema" type="text" />
        <textarea placeholder="填写内容" v-model="message" type="text" />

      <p @click="submit()" class="zsdiv5div4">提交</p>
    </div>
      </div>

      
      <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/HoMe.vue";
import Footer from "@/components/Footer.vue";
import Address from "@/components/Address.vue";
export default {
  name: "Contact",
  data() {
    return {name: "",
      phone: "",
      ema: "",
      message: "",};
  },

  methods:{
    submit() {
      var reg = new RegExp(
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\\d{8}$"
      );
      if (this.name === "") {
        alert("请输入您的姓名");
      } else if (this.phone === "") {
        //输入不能为空
        alert("电话号码不能为空");
      } else if (!reg.test(this.phone)) {
        //正则验证不通过，格式不对
        alert("电话号码格式不正确");
      } else if (this.ema === "") {
        alert("请填写您的邮箱");
      } else {
        console.log(this.name, this.phone, this.message, this.addressqs);
        var comment =
          "姓名：" +
          this.name +
          ",手机号：" +
          this.phone +
          ",电子邮箱：" +
          this.ema +
          ",留言内容：" +
          this.message;
        console.log(comment);
        this.$axios
          .post(
            "https://www.d-shang.com/index.php?comment/comment/?",
            {
              nid: 28911,
              real_name: this.name,
              mobile_phone: this.phone,
              comment: comment,
            },
            { emulateJSON: true }
          )
          .then(function (res) {
            console.log(res);
          });
        alert("提交成功");
        location.reload();
      }
    }
  },

  components: {
    Header,
    Footer,
    Address
  },

  mounted(){},
};
</script>

<style lang="stylus" scoped>
 .zsdiv1{
   position:relative;
   top:230px;
   left:1143px;
   width:500px;
   height:400px;
   text-align: left;
 }

 .zsdiv1 input{
   outline: none;
  height: 36px;
  width: 360px;
  margin-right: 27px;
  // margin-left: 7px;
  color: #2c3e50;
  border: 1px solid #DCDFE6;
  border-radius: 1px;
  font-size: 14px;
  text-indent: 14px;
  display: block;
  margin-bottom:12px;
 }

  textarea {
  height: 80px;
  width: 346px;
  color: #2c3e50;
  border: 1px solid #DCDFE6;
  border-radius: 1px;
  font-size: 14px;
  outline: none;
  resize: none;
  padding-left:14px;
  padding-top: 10px;
  // margin-top:6px;
}

.zsdiv5div4 {
  margin-top:66px;
  width: 180px;
  background: #cbad65;
  height: 36px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  cursor: pointer;
}


.img1{
  margin-top:100px;
  height:1509px;
  width:100%;
  display: block;
  overflow: hidden;
}

.img2{
  // margin-top:100px;
  height:735px;
  width:100%;
  display: block;
  background-image: url("../../images/lxwm/3s.jpg")
  background-size:cover;
}

</style>

