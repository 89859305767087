<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import { watch, nextTick } from "vue";
// import { useRoute } from "vue-router";
// const route = useRoute;
// watch(()=>route.hash,(newVal,oldVal)=>{
//     let hash=window.location.hash;
//     hash=hash.slice(1);
//     if(hash.indexOf('#')!=-1){
//       nextTick(()=>{
//         let id=hash.split('#')[1];
//         id=decodeURIComponent(id);
//         let el=document.getElementById(id);
//         console.log('滚动到',id,el);
//         if(el){
//           setTimeout(()=>{
//             el.scrollIntoView({behavior:'smooth'});
//           },0);
//         }
//       })
//     }
//   })

export default {
  name: "App",
  components: {
    // HelloWorld
  },
};
</script>

<style>
#app {
  font-family: Noto Sans SC;
  font-style: normal;
  font-weight: 400;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

body {
  margin: 0;
  padding: 0;
}
</style>
