import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from '../buy/HoMe'
import index from '../buy/Index'
import space from '../buy/Space'
import about from '../buy/About'
import news from '../buy/News'
import join from '../buy/Join'
import contact from '../buy/Contact'
import newscontent from '../buy/NewsContent'
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {  //解决路由重复跳转问题
    return routerPush.call(this, location).catch(err => {})
};

// const Space = () => import('../buy/Space')

Vue.use(VueRouter)

const routes = [
    {path:'/',redirect:'/index'},
    { path: '/index',name:'Index',component: index ,},
    { path: '/space',name:'Space', component: space },
    { path: '/About',name:'About', component: about },
    { path: '/News',name:'News', component: news },
    { path: '/Join',name:'Join', component: join },
    { path: '/Contact',name:'Contact', component: contact },
    { path: '/NewsContent',name:'NewsContent', component: newscontent }

]

const router = new VueRouter({
    mode:'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 } // 将滚动位置设置为顶部
      }
})

export default router