<template>
<div>
  <div class="sy6">
      <div class="sy6div1">
        <img class="sy6img1" src="https://www.d-shang.com/image/xgw/sy/sy25.png" alt="" />
        <p class="sy6p1">浙江顶上集成家居股份有限公司</p>
        <p>地址：浙江省嘉兴市南湖区余新镇经二路东侧1幢1楼</p>
        <p>
          <span>全国服务热线：</span>
          <span class="sy6span1">0573-83253333</span>
        </p>
        <div class="sy6div1s">
          <span class="sy6div1sspan"
            ><img class="sy6img2" src="https://www.d-shang.com/image/xgw/sy/sy26.png" alt="" />
            <img class="sy6img2s" src="https://www.d-shang.com/image/xgw/sy/sy30.jpg" alt=""/>
            <div class="sy6div2s"></div>
            </span>
          <span class="sy6div1sspan"
            ><img class="sy6img2" src="https://www.d-shang.com/image/xgw/sy/sy27.png" alt="" />
            <img class="sy6img2s" src="https://www.d-shang.com/image/xgw/sy/sy31.jpg" alt=""/>
          <div class="sy6div2s"></div>
          </span>
          <span class="sy6div1sspan"
            ><img class="sy6img2" src="https://www.d-shang.com/image/xgw/sy/sy28.png" alt="" />
            <img class="sy6img2s" src="https://www.d-shang.com/image/xgw/sy/sy32.jpg" alt=""/>
          <div class="sy6div2s"></div>
          </span>
          <span class="sy6div1sspan"
            ><img class="sy6img2" src="https://www.d-shang.com/image/xgw/sy/sy29.png" alt="" />
            <img class="sy6img2s" src="https://www.d-shang.com/image/xgw/sy/sy33.jpg" alt=""/>
          <div class="sy6div2s"></div>
          </span>
        </div>
      </div>
      <div class="sy6div2">
        <ul>
          <li @click="route('/about')">
            <h2 class="sy6div2h2">关于顶上</h2>
            <p class="sy6div2p">顶上简介</p>
            <p class="sy6div2p">顶上文化</p>
            <p class="sy6div2p">发展历程</p>
            <p class="sy6div2p">顶上荣誉</p>
          </li>
          <li @click="route('/space')">
            <h2 class="sy6div2h2">空间灵感</h2>
            <p class="sy6div2p">集成吊顶</p>
            <p class="sy6div2p">集成墙面</p>
            <p class="sy6div2p">定制门柜</p>
            <p class="sy6div2p">阳台生活</p>
          </li>
          <li @click="route('/news')"> 
            <h2 class="sy6div2h2">新闻中心</h2>
            <p class="sy6div2p">品牌资讯</p>
            <p class="sy6div2p">产品资讯</p>
            <p class="sy6div2p">活动资讯</p>
          </li>
          <li @click="route('/join')">
            <h2 class="sy6div2h2">招商加盟</h2>
            <p class="sy6div2p">品牌优势</p>
            <p class="sy6div2p">加盟支持</p>
            <p class="sy6div2p">加盟流程</p>
          </li>
        </ul>
      </div>
    </div>
     <div class="footer">
    <a href="https://beian.miit.gov.cn">浙ICP备2023010796号</a>
     </div>

    

    </div>
</template>

<script>
import axios from "axios";
import md5 from 'js-md5';
export default {
  name: "Footer",
  data() {
    return {
      kjl: [],
      sign: [],
    };
  },

  methods: {

    route(path){
      this.$router.push({
        path:path
      })
    },
    kjlData() {
      const appkey = "Jj6sgIVmdW";
      const timestamp = new Date().getTime();
      const appsecret = "BRqE1LH6fSZiqCHTN3CaxM2G0QAw3GlY";
      const appuid = "3FO4KDXV5WIS";
      const sign = md5(appsecret + appkey + appuid + timestamp);
      // axios.post('https://openapi.kujiale.com/v2/design/list?start=0&num=50&sort=0&appuid=3FO4KDXV5WIS&time=1560528486000&='+this.sign).then(res=>{
      //   this.kjl=res.data;
      // })

      // .catch(error=>{
      //   console.error('获取数据失败：',error);
      // })

      const apiUri = "/abc/v2/design/list/";
      // 拼接完整的请求URL
      const uri =
        apiUri +
        "?appkey=" +
        appkey +
        "&timestamp=" +
        timestamp +
        "&sign=" +
        sign +
        "&appuid=3FO4KDXV5WIS";
      // 发起请求
      axios({
        method: "get",
        url: uri,
        data: {
          start: 0,
          num: 50,
          sort: 0,
          appuid: "3FO4KDXV5WIS",
          time: timestamp,
        },
      })
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted: function () {
    this.kjlData();
  },
};
</script>

<style lang="stylus" scoped>
.footer a{
   color:#808080;
   line-height: 50px;
   font-size:12px;
 }

  .footer{
    height:50px;
    display: block;
    background:#303133;
  }

  .sy6div2{
    margin-right:110px;
  }

 .sy6div2 ul li{
   margin-left:75px;
 }

 .sy6div2s{
   position:relative;
   top:-179px;
   left:18px;
   width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    display:none;
 }

  .sy6div1s .sy6div1sspan{
   float:left;
   width: 70px;
   height:60px;
 }

  .sy6img2s{
    height:103px;
    width:103px;
    position:relative;
    top:-176px;
    left:-21px;
    display:none;
  }

  .sy6div1sspan:hover .sy6div2s{
    display:block;   
  }

  .sy6div1sspan:hover .sy6img2s{
    display:block;   
  }

.sy6div2h2 {
  white-space:nowrap;
  width:22px;
  font-weight: 200;
  padding-bottom: 20px;
  border-bottom: 2px solid #cbad65;
  font-size:18px;
}

.sy6div2p {
  color: #808080;
  cursor: pointer;
}

.sy6div2p:hover {
  color: #ffffff;
}

.sy6img2 {
  width:56px;
  height:57px;
  position:relative;
  top:0;
  left:0;
}

.sy6div1s {

}

.sy6span1 {
  color: #cbad65;
  font-size: 18px;
}

.sy6div1 p {
  font-size: 14px;
  margin-left: 6px;
}

.sy6p1 {
  margin-top: 30px;
}

.sy6div1 {
  text-align: left;
  margin-left:110px;
}

.sy6img1 {
  heigth: 45px;
  width: 277px;
}

.sy6 {
  display: flex;
  justify-content: space-around;
  background: #000;
  height: 350px;
  padding-top: 80px;
  color: #808080;
}

.sy6 .sy6div2 ul {
  margin: 0px;
  text-align: left;
}

</style>