<template>
  <div>
    <Header></Header>
    <img class="zsimg1" src="../../images/zsjm/1.jpg" alt="" />
    <img class="zsimg2" src="../../images/zsjm/2.jpg" alt="" />
    <div class="zsdiv1">
      <img
        :style="{ display: col1 == 1 ? 'block' : 'none' }"
        class="zsimg3"
        src="../../images/zsjm/31.jpg"
        alt=""
      />
      <img
        :style="{ display: col2 == 1 ? 'block' : 'none' }"
        class="zsimg3"
        src="../../images/zsjm/32.jpg"
        alt=""
      />
      <img
        :style="{ display: col3 == 1 ? 'block' : 'none' }"
        class="zsimg3"
        src="../../images/zsjm/33.jpg"
        alt=""
      />
      <img
        :style="{ display: col4 == 1 ? 'block' : 'none' }"
        class="zsimg3"
        src="../../images/zsjm/34.jpg"
        alt=""
      />
      <ul>
        <li
          :style="{ color: col1 == 1 ? '#000' : '#fff' }"
          @mouseenter="handleMouseEnter1"
        >
          <a :class="col1 == 1 ? 'a1s' : 'a1'"></a>
          <p>拥有占地面积近两百亩的产业园生产基地将近三十万方</p>
        </li>

        <li
          :style="{ color: col2 == 1 ? '#000' : '#fff' }"
          @mouseenter="handleMouseEnter2"
          class="li1"
        >
          <a :class="col2 == 1 ? 'a1s' : 'a1'"></a>
          <p>引进行业先进德国豪迈生产设备</p>
        </li>
        <li
          :style="{ color: col3 == 1 ? '#000' : '#fff' }"
          @mouseenter="handleMouseEnter3"
          class="li1"
        >
          <a :class="col3 == 1 ? 'a1s' : 'a1'"></a>
          <p>意大利压贴生产线</p>
        </li>
        <li
          :style="{ color: col4 == 1 ? '#000' : '#fff' }"
          @mouseenter="handleMouseEnter4"
          class="li1"
        >
          <a :class="col4 == 1 ? 'a1s' : 'a1'"></a>
          <p>打造高效率、高质量产品交付体系</p>
        </li>
      </ul>
    </div>

    <div class="gydiv4" id="gydiv4md">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in item" :key="index">
          <div
            style="
              color: #000000;
              text-align: center;
              overflow: hidden;
              width: 100%;
            "
          >
            <img
              style="width: 100%; height: auto"
              class="bannertt"
              :src="item.url"
              alt="Image"
            />
          </div>
          <!-- <h3>{{ item }}</h3> -->
        </el-carousel-item>
      </el-carousel>
    </div>

    <img class="zsimg4" src="../../images/zsjm/5.jpg" alt="" />

    <div class="zsdiv2">
      <div class="zsdiv21">
        <img
          :style="{ display: ry1 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy1"
          src="../../images/zsjm/ys/1.png"
          alt=""
        />
        <img
          :style="{ display: ry1 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/1s.png"
          alt=""
        />
        <img
          :style="{ display: ry2 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy2"
          src="../../images/zsjm/ys/2.png"
          alt=""
        />
        <img
          :style="{ display: ry2 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/2s.png"
          alt=""
        />
        <img
          :style="{ display: ry3 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy3"
          src="../../images/zsjm/ys/3.png"
          alt=""
        />
        <img
          :style="{ display: ry3 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/3s.png"
          alt=""
        />
        <img
          :style="{ display: ry4 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy4"
          src="../../images/zsjm/ys/4.png"
          alt=""
        />
        <img
          :style="{ display: ry4 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/4s.png"
          alt=""
        />
      </div>

      <div class="zsdiv21s">
        <img
          :style="{ display: ry5 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy5"
          src="../../images/zsjm/ys/5.png"
          alt=""
        />
        <img
          :style="{ display: ry5 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/5s.png"
          alt=""
        />
        <img
          :style="{ display: ry6 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy6"
          src="../../images/zsjm/ys/6.png"
          alt=""
        />
        <img
          :style="{ display: ry6 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/6s.png"
          alt=""
        />
        <img
          :style="{ display: ry7 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy7"
          src="../../images/zsjm/ys/7.png"
          alt=""
        />
        <img
          :style="{ display: ry7 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/7s.png"
          alt=""
        />
        <img
          :style="{ display: ry8 != 1 ? 'block' : 'none' }"
          @mouseenter="ssy8"
          src="../../images/zsjm/ys/8.png"
          alt=""
        />
        <img
          :style="{ display: ry8 == 1 ? 'block' : 'none' }"
          src="../../images/zsjm/ys/8s.png"
          alt=""
        />
      </div>
    </div>

    <div class="zsdiv3">
      <div class="zsdiv3div1">
        <img src="../../images/zsjm/bw/b21.png" alt="" />
        <img class="zsimg5" src="../../images/zsjm/bw/b21s.png" alt="" />
      </div>
      <div>
        <img src="../../images/zsjm/bw/b2.png" alt="" />
        <img class="zsimg5" src="../../images/zsjm/bw/b2ss.png" alt="" />
      </div>
      <div>
        <img src="../../images/zsjm/bw/b3.png" alt="" />
        <img class="zsimg5" src="../../images/zsjm/bw/b3ss.png" alt="" />
      </div>
      <div class="zsdiv3div2">
        <img src="../../images/zsjm/bw/b4.png" alt="" />
        <img class="zsimg5" src="../../images/zsjm/bw/b4ss.png" alt="" />
      </div>
    </div>

    <div class="zsdiv4">
      <div class="zsdiv41s">
        <div class="zsdiv4div1">
          <img src="../../images/zsjm/lc/1.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/1s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/11.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/2.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/2s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/11.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/3.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/3s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/11.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/4.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/4s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/11.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/5.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/5s.jpg" alt="" />
          <img id="zsimg8" src="https://www.d-shang.com/image/xgw/zsjm/lc/12.png" alt="" />
        </div>
      </div>

      <div class="zsdiv42s">
        <div>
          <img src="../../images/zsjm/lc/10.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/10s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/13.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/9.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/9s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/13.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/8.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/8s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/13.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/7.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/7s.jpg" alt="" />
          <img id="zsimg7" src="https://www.d-shang.com/image/xgw/zsjm/lc/13.png" alt="" />
        </div>
        <div>
          <img src="../../images/zsjm/lc/6.jpg" alt="" />
          <img class="zsimg6" src="../../images/zsjm/lc/6s.jpg" alt="" />
        </div>
      </div>
    </div>

    <div class="zsdiv5">
      <div class="zsdiv5div1">
        <input placeholder="请输入姓名" v-model="name" type="text" />
        <input placeholder="请输入手机号码" v-model="phone" type="text" />
      </div>

      <div class="zsdiv5div2">
        <el-form>
          <el-form-item label="" prop="address" v-model="ruleForm.address">
            <checkAddress
              ref="address"
              v-model="ruleForm.address"
              :form="ruleForm.address"
            />
          </el-form-item>
        </el-form>
      </div>

      <div class="zsdiv5div3">
        <!-- <input placeholder="留言" v-model=num type="text"> -->
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="留言"
          v-model="message"
        ></textarea>
      </div>

      <p @click="submit()" class="zsdiv5div4">确认提交</p>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/HoMe.vue";
import Footer from "@/components/Footer.vue";
import checkAddress from "@/components/checkAddress.vue";
import img1 from "../../images/lb/1.jpg"
import img2 from "../../images/lb/2.jpg"
import img3 from "../../images/lb/3.jpg"
import img4 from "../../images/lb/4.jpg"
import img5 from "../../images/lb/5.jpg"
import img6 from "../../images/lb/6.jpg"
import img7 from "../../images/lb/7.jpg"
import img8 from "../../images/lb/8.jpg"
export default {
  name: "Join",
  data() {
    return {
      name: "",
      phone: "",
      addressqs: "",
      message: "",
      col1: 1,
      col2: 2,
      col3: 2,
      col4: 2,
      item:[{ id: 1, type: "image", url: img1 },
        { id: 2, type: "image", url: img2 },
        { id: 3, type: "image", url: img3 },
        { id: 4, type: "image", url: img4 },
        { id: 5, type: "image", url: img5 },
        { id: 6, type: "image", url: img6 },
        { id: 7, type: "image", url: img7 },
        { id: 8, type: "image", url: img8 }],
      ry1: 0,
      ry2: 0,
      ry3: 0,
      ry4: 0,
      ry5: 0,
      ry6: 0,
      ry7: 0,
      ry8: 0,
      supplierList: [],
      direction: "btt",
      ifCreate: false,
      ruleForm: {
        address: {
          // 省
          province: "",
          // 市
          city: "",
          // 区
          district: "",
          //详细地址
          detail: "",
        },
      },
      rules: {
        address: [{ required: true, validator: validatePass, trigger: "blur" }],
      },
    };
    var validatePass = (rule, value, callback) => {
      if (
        this.ruleForm.address.province == "" ||
        this.ruleForm.address.detail == ""
      ) {
        callback(new Error("请输入完整地址"));
      }
      callback();
    };
  },

  watch: {
    ruleForm: {
      deep: true,
      handler() {
        this.ruleForm.address = this.$refs.address.form;
        this.addressqs =
          this.ruleForm.address.province +
          this.ruleForm.address.city +
          this.ruleForm.address.district;
        console.log(this.addressqs, "88888");
      },
    },
  },

  methods: {
    submit() {
      var reg = new RegExp(
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\\d{8}$"
      );
      if (this.name === "") {
        alert("请输入您的姓名");
      } else if (this.phone === "") {
        //输入不能为空
        alert("手机号码不能为空");
      } else if (!reg.test(this.phone)) {
        //正则验证不通过，格式不对
        alert("手机号格式不正确");
      } else if (this.addressqs === "") {
        alert("请选择您所在的地址");
      } else {
        console.log(this.name, this.phone, this.message, this.addressqs);
        var comment =
          "姓名：" +
          this.name +
          ",手机号:" +
          this.phone +
          ",门店地址:" +
          this.addressqs +
          ",备注:" +
          this.message;
        console.log(comment);
        this.$axios
          .post(
            "https://www.d-shang.com/index.php?comment/comment/?",
            {
              nid: 28911,
              real_name: this.name,
              mobile_phone: this.phone,
              comment: comment,
            },
            { emulateJSON: true }
          )
          .then(function (res) {
            console.log(res);
          });
        alert("提交成功");
        location.reload();
      }
    },

    handleMouseEnter1() {
      this.col1 = 1;
      this.col2 = 2;
      this.col3 = 2;
      this.col4 = 2;
    },
    handleMouseEnter2() {
      this.col1 = 2;
      this.col2 = 1;
      this.col3 = 2;
      this.col4 = 2;
    },
    handleMouseEnter3() {
      this.col1 = 2;
      this.col2 = 2;
      this.col3 = 1;
      this.col4 = 2;
    },
    handleMouseEnter4() {
      this.col1 = 2;
      this.col2 = 2;
      this.col3 = 2;
      this.col4 = 1;
    },
    ssy1() {
      this.ry1 = 1;
      this.ry2 = 0;
      this.ry3 = 0;
      this.ry4 = 0;
      this.ry5 = 0;
      this.ry6 = 0;
      this.ry7 = 0;
      this.ry8 = 0;
    },
    ssy2() {
      this.ry1 = 0;
      this.ry2 = 1;
      this.ry3 = 0;
      this.ry4 = 0;
      this.ry5 = 0;
      this.ry6 = 0;
      this.ry7 = 0;
      this.ry8 = 0;
    },
    ssy3() {
      this.ry1 = 0;
      this.ry2 = 0;
      this.ry3 = 1;
      this.ry4 = 0;
      this.ry5 = 0;
      this.ry6 = 0;
      this.ry7 = 0;
      this.ry8 = 0;
    },
    ssy4() {
      this.ry1 = 0;
      this.ry2 = 0;
      this.ry3 = 0;
      this.ry4 = 1;
      this.ry5 = 0;
      this.ry6 = 0;
      this.ry7 = 0;
      this.ry8 = 0;
    },
    ssy5() {
      this.ry1 = 0;
      this.ry2 = 0;
      this.ry3 = 0;
      this.ry4 = 0;
      this.ry5 = 1;
      this.ry6 = 0;
      this.ry7 = 0;
      this.ry8 = 0;
    },
    ssy6() {
      this.ry1 = 0;
      this.ry2 = 0;
      this.ry3 = 0;
      this.ry4 = 0;
      this.ry5 = 0;
      this.ry6 = 1;
      this.ry7 = 0;
      this.ry8 = 0;
    },
    ssy7() {
      this.ry1 = 0;
      this.ry2 = 0;
      this.ry3 = 0;
      this.ry4 = 0;
      this.ry5 = 0;
      this.ry6 = 0;
      this.ry7 = 1;
      this.ry8 = 0;
    },
    ssy8() {
      this.ry1 = 0;
      this.ry2 = 0;
      this.ry3 = 0;
      this.ry4 = 0;
      this.ry5 = 0;
      this.ry6 = 0;
      this.ry7 = 0;
      this.ry8 = 1;
    },
  },

  components: {
    Header,
    Footer,
    checkAddress,
  },

  mounted() {},
};
</script>

<style lang="stylus" scoped>
.zsdiv5div4 {
  margin: 0 0 0 1008px;
  width: 404px;
  background: #cbad65;
  height: 36px;
  text-align: center;
  line-height: 36px;
  color: #fff;
  cursor: pointer;
}

.zsdiv5 input {
  outline: none;
  height: 34px;
  width: 180px;
  margin-right: 27px;
  margin-left: 7px;
  color: #C0C4CC;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  font-size: 14px;
  text-indent: 14px;
}

input::-webkit-input-placeholder { /* WebKit browsers */
  color: #C0C4CC;
}

textarea::-webkit-input-placeholder { /* WebKit browsers */
  color: #C0C4CC;
}

.zsdiv5div1 {
  margin: 210px 0 18px 970px;
  width: 500px;
}

.zsdiv5div2 {
  margin: 0 0 0 970px;
  width: 500px;
}

.zsdiv5div3 {
  margin: -20px 0 18px 970px;
  width: 500px;
  text-align: left;
}

.zsdiv5div3 textarea {
  margin-left: 37px;
  height: 54px;
  width: 400px;
  color: #C0C4CC;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  resize: none;
  text-indent: 14px;
  padding-top: 10px;
}

ul {
  display: block !important;
}

.zsdiv5 {
  height: 590px;
  width: 100%;
  background: url('../../images/zsjm/hrzs.jpg');
  background-size: cover;
  overflow: hidden;
}

#zsimg8 {
  height: 28px;
  width: 28px;
  margin: 0;
  padding: 0;
  position: relative;
  top: -155px;
  left: -13px;
}

#zsimg7 {
  height: 28px;
  width: 28px;
  margin: 0;
  padding: 0;
  position: relative;
  top: -300px;
  left: 120px;
}

.zsdiv4div1 {
  margin-left: 50px;
}

.zsimg6 {
  position: relative;
  top: -185.5px;
  opacity: 0;
}

.zsimg6:hover {
  opacity: 1;
}

.zsdiv4 div img {
  height: 182px;
  width: 246px;
  margin-right: 40px;
}

.zsdiv4 div {
  cursor: pointer;
}

.zsdiv41s {
  margin-top: 240px;
  display: flex;
  height: 182px;
  width: 246px;
  justify-content: flex-start;
  margin-left: 200px;
}

.zsdiv42s {
  margin-top: 90px;
  display: flex;
  height: 182px;
  width: 246px;
  margin-left: 260px;
  justify-content: flex-start;
}

.zsdiv4 {
  height: 795px;
  width: 100%;
  background: url('../../images/zsjm/lc/bjs.jpg');
  background-size: cover;
  overflow: hidden;
}

.zsdiv3div1 {
  margin-left: 250px;
}

.zsdiv3div2 {
  margin-right: 250px;
}

.zsimg5 {
  position: relative;
  top: -422px;
  opacity: 0;
}

.zsimg5:hover {
  opacity: 0.9;
}

.zsdiv3 div img {
  height: 418px;
  width: 315px;
}

.zsdiv3 div {
  margin-top: 350px;
}

.zsdiv3 {
  display: flex;
  height: 844px;
  width: 100%;
  background: url('../../images/zsjm/bw/bj.jpg');
  background-size: cover;
  overflow: hidden;
}

.zsdiv2 {
  height: 829px;
  width: 100%;
  background: url('../../images/zsjm/ys/bj.jpg');
  background-size: cover;
  overflow: hidden;
}

.zsdiv21 {
  display: flex;
  margin-top: 350px;
  margin-left: 128px;
  margin-bottom: 50px;
}

.zsdiv2 img {
  height: 141px;
  width: 407px;
}

.zsdiv21s {
  display: flex;
  margin-left: 128px;
  margin-bottom: 50px;
}

.zsdiv1 {
  height: 767px;
  width: 100%;
  // background:red;
}

.zsdiv1 .div1 {
  // background:pink;
}

.zsdiv1 ul {
  width: 678px;
  height: 468px;
  // background:pink;
  margin: 0;
  padding: 0;
  position: relative;
  left: 1052px;
  top: -542px;
  display: block;
  overflow: hidden;
}

.zsdiv1 ul li {
  font-size: 24px;
  width: 360px;
  text-align: left;
  margin-top: 40px;
  margin-left: 80px;
  cursor: pointer;
  // color:#000
}

// .zsdiv1 ul li:hover{
// color: #000;
// }
.zsdiv1 ul .li1 {
  font-size: 24px;
  width: 360px;
  text-align: left;
  height: 50px;
  margin-top: 20px;
}

.zsdiv1 ul li .a1 {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 20px;
  // float:left;
  position: relative;
  top: 29px;
  left: -20px;
}

.zsdiv1 ul li .a1s {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #000;
  border-radius: 20px;
  // float:left;
  position: relative;
  top: 29px;
  left: -20px;
}

.zsdiv1 ul li p {
  margin: 0;
}

.zsimg1 {
  height: 829px;
  width: 100%;
  margin-top: 87px;
}

.zsimg2 {
  height: 1188px;
  width: 100%;
}

.zsimg3 {
  height: 767px;
  width: 100%;
}

.el-carousel {
  width: 1339px;
  height: 406px;
  overflow: hidden;
  margin-top: 240px;
  margin-left: 100px;
  padding-left: 200px;
  padding-right: 200px;

  .el-carousel__item:nth-child(2n) {
    background-color: #ffffff;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #ffffff;
  }

  /deep/ .el-carousel__indicators { // 指示器
    left: unset;
    transform: unset;
    right: 48%;
    bottom: -50px;
    display: none;
  }

  /deep/ .el-carousel__button { // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 1;
  }

  /deep/ .is-active .el-carousel__button { // 指示器激活按钮
    background: #cbad65;
    opacity: 0;
  }

  /deep/ .el-carousel__container {
    height: 100%;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
  margin: 0;
  padding: 0;
}

// /* 左右箭头宽高 */
/deep/ .el-carousel__arrow {
  height: 40px;
  width: 40px;
}

// 切换左右箭头样式
/deep/ .el-carousel__arrow--left {
  color: transparent;
  // 设置背景图片
  background: url('../../images/gyds/jt1.png') no-repeat center center;
  background-size: 40px;
  position: relative;
  left: -720px;
}

/deep/ .el-carousel__arrow--right {
  color: transparent;
  // 设置背景图片
  background: url('../../images/gyds/jt2.png') no-repeat center center;
  background-size: 40px;
  position: relative;
  right: -720px;
}

@media (max-width: 1830px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    right: -720px;
  }
}

@media (max-width: 1823px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 36px;
    right: -620px;
  }
}

@media (max-width: 1623px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    right: -590px;
    background-size: 36px;
    background-size: 30px;
  }
}

@media (max-width: 1523px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 30px;
    right: -560px;
  }
}

@media (max-width: 1400px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 30px;
    right: -510px;
  }
}

@media (max-width: 1300px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 30px;
    right: -490px;
  }
}

@media (max-width: 1250px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 26px;
    right: -450px;
  }
}

@media (max-width: 1100px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 26px;
    right: -400px;
  }
}

@media (max-width: 1000px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 22px;
    right: -370px;
  }
}

@media (max-width: 900px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 20px;
    right: -340px;
  }
}

@media (max-width: 800px) {
  /deep/ .el-carousel__arrow--right {
    position: relative;
    background-size: 18px;
    right: -300px;
  }
}

/deep/ .el-carousel__arrow--left:hover {
  background: url('../../images/gyds/jt1.png') no-repeat center center;
}

/deep/ .el-carousel__arrow--right:hover {
  background: url('../../images/gyds/jt2.png') no-repeat center center;
}

.gydiv4 {
  height: 745px;
  width: 100%;
  // background: url('../../images/gyds/7.jpg');
  background: url('../../images/zsjm/4.jpg');
  background-size: cover;
  display: flex;
}

.zsimg4 {
  height: 2428px;
  width: 100%;
  display: block;
}
</style>

