<template>
  <div>
    <Header></Header>
    <div class="xwdiv1">
       <img class="xwdiv1img1" src="../../images/xwzx/1.jpg" alt="">
      </div>
    <div class="xwcontent">
      <div class="content_txt wrap">
        <!-- {{list.content}} -->
        <h1 class="context_txt_title"></h1>
        <h2 class="context_txt_time"></h2>
        <div class="con"></div>
      </div>
    </div>
    <Footer></Footer>
    <p @click="fh()">返回</p>
  </div>
</template>

<script>
import Header from "@/components/HoMe.vue";
import Footer from "@/components/Footer.vue";
import $ from "jquery";
export default {
  name: "NewsContent",
  data() {
    return {
      pagecount: 1,
      id: "",
      list: [],
    };
  },

  methods: {
    getLocalTime(nS) {
      //将时间戳转换成时间
      return new Date(parseInt(nS) * 1000)
        .toLocaleString()
        .replace(/:\d{1,2}$/, " ");
    },
    listtop1() {
      var that = this;
      this.$axios
        .get(
          "https://www.d-shang.com/index.php?app/getnewsconbyid/?id=" +
            that.id +
            "&openid=tIy43Mjm/ppXwj3ISqtaZdzRCw"
        )

        .then(function (res) {
          // console.log(res.data.data);
          that.list = res.data.data;
          console.log(that.list);
          $(".context_txt_title").text(that.list.title);

          var addtime = that.getLocalTime(that.list.date);

          $(".context_txt_time").text(addtime);
          $(".con").html(that.list.content);
          // that.pagecount = res.data.message.page;
        });
    },

    fh(){
      // history.go(-1);
      window.history.back();
    }
  },

  components: {
    Header,
    Footer,
  },

  mounted() {
    this.id = this.$route.query.id;
    console.log(this.id);
    this.listtop1();
  },
};
</script>

<style lang="stylus" scoped>
 p{
   font-size:18px;
  //  border:1px solid red;
  color:#606266;
  line-height: 30px;
   height: 30px;
   width:80px;
   position:fixed;
   bottom:100px;
   right:150px;
   cursor:pointer;
   background:#e4e4e4;
   border-radius:5px;
 }

 p:hover{
   background:#000;
   color:#cbad65;
 }

 .xwdiv1img1{
  width: 100%;
  height:531px;
  display: flex;
  margin-top:87px;
}


.xwcontent {
  width: 100%;
  margin-top: 57px;
  overflow: hidden;
  text-align: left;
}

.context_txt_title {
  text-align: center;
  font-size: 26px;
  color: #000;
  line-height: 36px;
  margin-bottom: 10px;
  font-weight: normal;
}

.context_txt_time {
  font-size: 14px;
  font-weight: normal;
  color: #999;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  width: 100%;
  padding-bottom: 10px;
}

/deep/ .con p {
  font-size: 16px;
  margin: 0;
}

.wrap {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}
// .content_txt .con p img {
//     max-width: 100%;
// }
/deep/ .wrap img{
    width:900px;
    display: block;
    padding: 0;
}
</style>

