import Vue from 'vue';
import Vuex from 'Vuex';
Vue.use(Vuex)

let store=new Vuex.Store({
    mutations:{
        handleMouseEnter1() {
            this.lm2 = false;
            this.lm3 = false;
            this.lm4 = false;
            this.lm1 = true;
            this.p=1;
            console.log(this.lm1, this.lm2, this.lm3, this.lm4);
            if(this.listtop1s){
              this.listtop1();
              console.log('进入判断');
            }else if(this.listtop2s){
              this.listtop2();
            }else if(this.listtop3s){
              this.listtop3();
            }else if(this.listtop4s){
              this.listtop4();
            }else if(this.listtop5s){
              this.listtop5();
            }
      
          },
          handleMouseEnter2() {
            this.lm1 = false;
            this.lm3 = false;
            this.lm4 = false;
            this.lm2 = true;
            this.p=1;
            console.log(this.lm1, this.lm2, this.lm3, this.lm4);
            if(this.listtop1s){
              this.listtop12();
            }else if(this.listtop2s){
              this.listtop22();
            }else if(this.listtop3s){
              this.listtop32();
            }else if(this.listtop4s){
              this.listtop42();
            }else if(this.listtop5s){
              this.listtop52();
            }
      
          },
          handleMouseEnter3() {
            this.lm2 = false;
            this.lm1 = false;
            this.lm4 = false;
            this.lm3 = true;
            this.p=1;
            console.log(this.lm1, this.lm2, this.lm3, this.lm4);
            if(this.listtop1s){
              this.listtop13();
            }else if(this.listtop2s){
              this.listtop23();
            }else if(this.listtop3s){
              this.listtop33();
            }else if(this.listtop4s){
              this.listtop43();
            }else if(this.listtop5s){
              this.listtop53();
            }
      
          },
          handleMouseEnter4() {
            this.lm2 = false;
            this.lm3 = false;
            this.lm1 = false;
            this.lm4 = true;
            this.p=1;
            console.log(this.lm1, this.lm2, this.lm3, this.lm4);
            if(this.listtop1s){
              this.listtop14();
            }else if(this.listtop2s){
              this.listtop24();
            }else if(this.listtop3s){
              this.listtop34();
            }else if(this.listtop4s){
              this.listtop44();
            }else if(this.listtop5s){
              this.listtop54();
            }
      
          },
    }

})

export default store