<template>
  <!-- 百度地图  -->
  <div id="bai-du-map">
    <!-- 技术支持和联系方式  -->
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
// 设置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: "fbc69e09e90cb3471fa601089b629a5c",
};
export default {
  name: "Address",
  props: ["iptId"],
  data() {
    return {
      map: null,
      mouseTool: null,
      overlays: [],
      auto: null,
      placeSearch: null,
    };
  },

  methods: {
    initMap() {
      AMapLoader.load({
        key: "07b1df2b500ccfba52cd15852c34d9a2", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.ToolBar",
          "AMap.ControlBar",
          "AMap.MouseTool",
          "AMap.MapType",
          "AMap.HawkEye",
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "SimpleMarker",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        // "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("bai-du-map", {
            viewMode: "2D", //  是否为3D地图模式
            zoom: 19, // 初始化地图级别
            center: [120.758932, 30.727817], //中心点坐标  公司
            resizeEnable: true,
          });

          this.mouseTool = new AMap.MouseTool(this.map);
          // 监听draw事件可获取画好的覆盖物
          this.mouseTool.on(
            "draw",
            function (e) {
              this.overlays.push(e.obj);
            }.bind(this)
          );

          this.map.addControl(new AMap.Scale()); // 添加左下角的比例尺
          let toolBar = new AMap.ToolBar({
            position: {
              bottom: "210px",
              right: "35px",
            },
          });
          let controlBar = new AMap.ControlBar({
            position: {
              bottom: "280px",
              right: "10px",
            },
          });
          this.map.addControl(toolBar); // 添加右下角的放大缩小
          this.map.addControl(controlBar); // 方向盘
          // this.map.addControl(new AMap.MapType());   // 添加右上角的标准图和卫星图  和路况
          this.map.addControl(new AMap.HawkEye()); // 添加地图放大镜

          this.auto = new AMap.AutoComplete({
            input: this.iptId, // 搜索框的id
          });
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map,
            panel: "panel", // 结果列表将在此容器中进行展示。
            // city: "010", // 兴趣点城市
            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
            extensions: "base", //返回基本地址信息
          });
          this.auto.on("select", this.select); //注册监听，当选中某条记录时会触发

          // 鼠标点击获取经纬度
          this.map.on("click", function (e) {
            console.log("经度", e.lnglat.getLng());
            console.log("纬度", e.lnglat.getLat());
          });
          //创建 AMap.Icon 实例：
          const icon = new AMap.Icon({
            size: new AMap.Size(40, 46), //图标尺寸
            image: "https://www.d-shang.com/static/adv/xgwdztb.png", //Icon 的图像
            imageOffset: new AMap.Pixel(0, 0), //图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(40, 46), //根据所设置的大小拉伸或压缩图片
          });
          //创建一个 Marker 实例：
          const marker = new AMap.Marker({
            position: new AMap.LngLat(120.758932, 30.727877), //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "浙江顶上集成家居股份有限公司",
            icon: icon,
          });
          //将创建的点标记添加到已有的地图实例：
          this.map.add(marker);

          //创建点标记的点击事件
          marker.on("click", function (e) {
            window.open(
              "https://www.amap.com/search?id=B0G39NMAO0&city=330402&geoobj=120.206942%7C30.462139%7C121.605601%7C31.087189&query_type=IDQ&query=%E6%B5%99%E6%B1%9F%E9%A1%B6%E4%B8%8A%E9%9B%86%E6%88%90%E5%AE%B6%E5%B1%85%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&zoom=20"
            );
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    select(e) {
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name); //关键字查询查询
    },

    draw(type) {
      switch (type) {
        case "marker": {
          this.mouseTool.marker({
            //同Marker的Option设置
          });
          break;
        }
        case "polyline": {
          this.mouseTool.polyline({
            strokeColor: "#80d8ff",
            //同Polyline的Option设置
          });
          break;
        }
        case "polygon": {
          this.mouseTool.polygon({
            fillColor: "#00b0ff",
            strokeColor: "#80d8ff",
            //同Polygon的Option设置
          });
          break;
        }
        case "rectangle": {
          this.mouseTool.rectangle({
            fillColor: "#00b0ff",
            strokeColor: "#80d8ff",
            //同Polygon的Option设置
          });
          break;
        }
        case "circle": {
          this.mouseTool.circle({
            fillColor: "#00b0ff",
            strokeColor: "#80d8ff",
            //同Circle的Option设置
          });
          break;
        }
      }
    },
  },

  components: {},

  mounted() {
    this.initMap();
  },
};
</script>

<style scoped>
#bai-du-map {
  overflow: hidden;
  width: 100%;
  height: 600px;
  margin: 0;
  font-family: Noto Sans SC;
}
</style>
<style>
/* 隐藏高德logo  */
.amap-logo {
  display: none !important;
}
/* 隐藏高德版权  */
.amap-copyright {
  display: none !important;
}
</style>