import Vue from 'vue';
import App from './App.vue';
import router from './route/index';
import axios from "axios";  //先cnpm安装
import jsCookie from 'js-cookie';  //先cnpm安装
import 'lib-flexible';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import preventMultiClick from './utils/preventMultiClick';
import { register } from 'swiper/element/bundle';
register();
// import register from 'swiper/element/bundle';
// Vue.use(register);
Vue.use(preventMultiClick);
Vue.use(ElementUI);
Vue.prototype.$cookie=jsCookie
Vue.prototype.$axios=axios;
import 'lib-flexible';

//导入仓库
import store from "./store/index"

Vue.config.productionTip = false

//如果将store挂载到根组件上
//那么子子孙孙都可以使用store
new Vue({
  store,   //将store挂载到根组件上面
  router,
  el:'#app',
  render: h => h(App),
}).$mount('#app')
